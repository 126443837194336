import React from "react"
import theme from "theme"
import {
  Theme,
  Text,
  Box,
  Button,
  Image,
  Section,
  Strong,
  Icon,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
import { MdDone } from "react-icons/md"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home | LuxeWheelz Premier Car Rentals</title>
        <meta
          name={"description"}
          content={"Experience Excellence on Every Journey"}
        />
        <meta property={"og:title"} content={"Home | LuxeWheelz Premier Car Rentals"} />
        <meta
          property={"og:description"}
          content={"Experience Excellence on Every Journey"}
        />
        <meta
          property={"og:image"}
          content={"https://yexonovaplus.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://yexonovaplus.com/img/car.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://yexonovaplus.com/img/car.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://yexonovaplus.com/img/car.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://yexonovaplus.com/img/car.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://yexonovaplus.com/img/car.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://yexonovaplus.com/img/car.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="0 0 0 0" quarkly-title="Hero-7">
        <Override
          slot="SectionContent"
          width="100%"
          flex-direction="row"
          max-width="100%"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="space-around"
          lg-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            padding="120px 50px 120px 50px"
            lg-width="100%"
            lg-padding="80px 50px 90px 50px"
            sm-padding="80px 25px 90px 25px"
            lg-display="flex"
            lg-flex-direction="column"
          >
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              padding="7px 20px 7px 20px"
              margin="0px 0px 30px 0px"
              border-radius="25px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              sm-flex-direction="column"
              lg-width="fit-content"
            >
              <Text
                margin="0px 10px 0px 0px"
                color="#8b9197"
                font="--base"
                sm-margin="0px 0 5px 0px"
              >
                Book with LuxeWheelz today and elevate your travel experience!
              </Text>
            </Box>
            <Text
              margin="0px 0px 20px 0px"
              font="normal 700 56px/1.2 --fontFamily-sans"
              sm-font="normal 700 42px/1.2 --fontFamily-sans"
            >
              LuxeWheelz Premier Cars
            </Text>
            <Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
            Here your journey begins with luxury. We specialize in providing an exceptional selection of high-end vehicles to meet the sophisticated tastes and needs of our discerning clients. Whether you're planning a business trip, a romantic getaway, or simply want to enjoy a day of driving pleasure, we offer the perfect blend of comfort, style, and performance.
            </Text>
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              sm-flex-direction="column"
              sm-align-items="stretch"
            >
              <Button
                margin="0px 15px 0px 0px"
                padding="12px 50px 12px 50px"
                background="--color-primary"
                border-radius="8px"
                font="normal 400 17px/1.5 --fontFamily-sans"
                sm-margin="0px 0 15px 0px"
                transition="background-color 0.2s ease 0s"
                border-width="2px"
                border-style="solid"
                border-color="--primary"
                hover-color="--darkL1"
                hover-background="rgba(63, 36, 216, 0)"
                href="/contacts"
                type="link"
                text-decoration-line="initial"
              >
                Contacts
              </Button>
            </Box>
          </Box>
          <Image
            src="https://yexonovaplus.com/img/1.jpg"
            display="block"
            width="50%"
            object-fit="cover"
            height="100%"
            lg-width="100%"
          />
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://yexonovaplus.com/img/2.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            About Us
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            LuxeWheelz stands out in the premium car rental market by combining luxury with unparalleled customer service. Our fleet includes top-tier brands such as Audi, Mercedes, BMW, Porsche, Range Rover, and more, ensuring that you drive only the best. Each vehicle in our collection is meticulously maintained and presented, promising not only supreme comfort but also the peace of mind that comes with a reliable and stylish ride.
          </Text>
        </Box>
      </Section>
      <Section
        padding="90px 0 90px 0"
        sm-padding="40px 0"
        sm-min-height="auto"
        display="flex"
        quarkly-title="Advantages/Features-8"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          grid-gap="5%"
          sm-min-width="280px"
          lg-flex-wrap="wrap"
          display="flex"
        />
        <Box
          width="60%"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          grid-gap="48px 32px"
          lg-grid-gap="48px"
          lg-width="100%"
          sm-grid-template-columns="1fr"
          md-grid-gap="36px "
        >
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                as="h1"
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Premium Selection</Strong>
              </Text>
              <Text
                as="h1"
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Enjoy access to a pristine fleet of luxury vehicles that redefine elegance and engineering excellence.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                as="h1"
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Tailored Experiences</Strong>
              </Text>
              <Text
                as="h1"
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
               We believe every trip is unique. Our team is dedicated to customizing your rental experience to perfectly suit your itinerary and preferences.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                as="h1"
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Transparent Pricing</Strong>
              </Text>
              <Text
                as="h1"
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                At LuxeWheelz, what you see is what you get. Enjoy straightforward pricing without hidden fees, ensuring your luxury experience starts and ends smoothly.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                as="h1"
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Dedicated Support</Strong>
              </Text>
              <Text
                as="h1"
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Our customer service is as elite as our vehicles. From booking to return, our team is here to ensure your satisfaction and comfort.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
